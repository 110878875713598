import React from "react";
import { Project } from "./projectCard";
import { ProjectCard } from "./projectCard";

export type ProjectProps = {
  projects: Project[];
};

export const Projects: React.FC<ProjectProps> = ({ projects }) => {
  return (
    <div className="flex flex-col pl-10 pb-20 md:pl-16 md:py-20 lg:pl-24 xl:pl-36 ">
      <h2 className="pb-10 text-2xl md:text-3xl font-display"> My Projects </h2>
      <div className="grid grid-flow-col auto-cols-max overflow-scroll">
        {projects.map((project) => (
          <ProjectCard project={project}></ProjectCard>
        ))}
      </div>
    </div>
  );
};
