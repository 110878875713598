import * as React from "react";
import Logo from "./Logo";

export type NavBarOption = {
  name: string;
  link: string;
};

export type NavBarProps = {
  options: NavBarOption[];
};

export const NavBar: React.FC<NavBarProps> = ({ options }) => {
  return (
    <nav className="flex flex-row justify-between items-center font-body px-10 py-5 md:py-10 lg:px-24 xl:px-36 ">
      <Logo />
      <ul className="hidden md:flex md:flex-row">
        {options.map((option) => (
          <li key={option.name}>
            <a href={option.link} className="py-2 px-5">
              {option.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
