import React from "react";

export type Project = {
  key: number;
  image: string;
  subheading: string;
  title: string;
  description: string;
  link: string;
};

export type ProjectCardProps = {
  project: Project;
};

const selectBackgroundColour = (key: number): string => {
  const colours = [
    "bg-primaryblue",
    "bg-primarygreen",
    "bg-primarypurple",
    "bg-primarylime",
  ];

  return colours[key % colours.length];
};

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const backgroundColour = selectBackgroundColour(project.key);

  return (
    <div
      className={`flex flex-col p-10 mr-8 w-72 md:w-96 rounded-lg ${backgroundColour}`}
    >
      <a href={project.link} target="_blank">
        <img src={project.image} alt="heroImage" className="w-96 pb-10" />
        <p className="pb-5 text-lg leading-8 ">{project.subheading}</p>
        <h1 className="font-display text-4xl pb-5 ">{project.title}</h1>
        <p className="text-lg leading-8 ">{project.description}</p>
      </a>
    </div>
  );
};
