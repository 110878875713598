import React from "react";

export type ButtonProps = {
  text: string;
  link: string;
};

export const Button: React.FC<ButtonProps> = ({ text, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      className="bg-black text-white font-bold px-8 py-4 rounded-lg"
    >
      {text}
    </a>
  );
};
