import React from "react";
import HeroLHS from "../../images/hero_lhs.png";

export type HeroProps = {
  projectImage: string;
  projectSubheading: string;
  projectHeading: string;
  projectLink: string;
  linkedinUrl: string;
};

export const Hero: React.FC<HeroProps> = ({
  projectImage,
  projectSubheading,
  projectHeading,
  projectLink,
  linkedinUrl,
}) => {
  return (
    <div className="block  md:grid md:grid-cols-2 gap-20 px-10 py-5 md:pt-6 md:pb-16 lg:px-24 xl:px-36">
      <div className="block md:flex md:flex-col pb-20">
        <h1 className="font-display text-center md:text-left text-3xl md:text-4xl pb-10">
          Hi! I’m Raghav <br /> 🚀🚀🚀{" "}
        </h1>
        <a href={linkedinUrl} target="_blank">
          <img src={HeroLHS} alt="heroImage" className="md:w-[30rem]" />
        </a>
      </div>

      <a href={projectLink} target="_blank" className="hidden md:block">
        <div className="flex flex-col items-end">
          <img src={projectImage} alt="heroImage" className="w-[22rem] pb-14" />
          <p className="pb-5 text-lg font-semibold leading-8">
            <i>{projectSubheading}</i>
          </p>
          <h1 className="font-display text-4xl pb-5">{projectHeading}</h1>
        </div>
      </a>
    </div>
  );
};
