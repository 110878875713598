import * as React from "react";
import logo from "../../images/icon.png";

const Logo = () => {
  return (
    <div className="flex items-center font-body ">
      <img src={logo} alt="logo" className="h-12 w-9" />
      <h1 className="text-xl pl-3 lg:text-2xl font-bold lg:pl-5">Raghav.</h1>
    </div>
  );
};

export default Logo;
