import React from "react";
import AboutMe from "../../images/aboutme.png";
import Linkedin from "../../images/LinkedIn.png";
import Instagram from "../../images/Instagram.png";
import Email from "../../images/Mail.png";

export type AboutProps = {
  title: string;
  description: string;
  linkedinUrl: string;
  instagramUrl: string;
  email: string;
};

// todo: add github?

export const About: React.FC<AboutProps> = ({
  title,
  description,
  linkedinUrl,
  instagramUrl,
  email,
}) => {
  return (
    <div className="md:grid md:grid-cols-5">
      <div className="flex flex-col col-span-3 justify-center px-10 py-20 md:py-36 md:pl-16 lg:pl-24 xl:pl-36">
        <h1 className="font-display text-2xl md:text-3xl pb-10">{title}</h1>
        <p className="pb-5 text-lg leading-8 md:w-[25rem]">
          {description.split("\n\n").map((line) => (
            <p className="pb-5">{line}</p>
          ))}
        </p>
        <div className="flex flex-row">
          <a href={linkedinUrl} target="_blank" className="pr-5 w-16">
            <img src={Linkedin} />
          </a>
          <a href={email} target="_blank" className="pr-5 w-16">
            <img src={Email} />
          </a>
          <a href={instagramUrl} target="_blank" className="pr-5 w-16">
            <img src={Instagram} />
          </a>
        </div>
      </div>

      <div className=" bg-primaryblue col-span-2 hidden md:flex md:flex-col justify-center items-center">
        <img src={AboutMe} className="scale-90 lg:scale-75" />
      </div>
    </div>
  );
};
