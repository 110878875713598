import * as React from "react";
import { Helmet } from "react-helmet";

import { About, AboutProps } from "../components/about";
import { Footer, FooterProps } from "../components/footer";
import { Hero, HeroProps } from "../components/hero";
import { NavBar, NavBarOption, NavBarProps } from "../components/navbar/index";
import { Projects } from "../components/projects";
import { Project } from "../components/projects/projectCard";

import GithubProjectImage from "../images/Github_Project_Image.png";
import AdviceGeneratorImage from "../images/Advice_Generator_Project.png";
import RatingComponentProject from "../images/Rating_Component_Project.png";
import ELearningLandingProject from "../images/E-Learning_Landing_Project.png";
import PasswordGeneratorProject from "../images/password_generator_project.png";

import favicon from "../images/Favicon.png";

// markup
const IndexPage = () => {
  const navBarOptions: NavBarOption[] = [
    {
      name: "About",
      link: "#about",
    },
    {
      name: "Projects",
      link: "#projects",
    },
    {
      name: "Contact",
      link: "#contact",
    },
  ];

  const heroProps: HeroProps = {
    projectImage: PasswordGeneratorProject,
    projectSubheading: "Featured Project ❗",
    projectHeading: "Password Generator",
    projectLink: "https://password-generator-jade-eight.vercel.app/",
    linkedinUrl: "https://www.linkedin.com/in/raghavramanathan/",
  };

  const aboutProps: AboutProps = {
    title: "About Me 🫠",
    description: `Hola! I’m Raghav - a fourth-year software engineering and commerce student with a deep interest in how technology can help streamline business processes. 
      \n\n
      I’ve worked for both a startup and not-for-profit over the past 24 months, along with a web development agency. 
      \n\n
      Feel free to reach out if you’d like to talk!`,
    linkedinUrl: "https://www.linkedin.com/in/raghavramanathan/",
    instagramUrl: "https://www.instagram.com/raghavr_/",
    email: "mailto:rramanathanraghav@gmail.com",
  };

  const projects: Project[] = [
    {
      key: 0,
      image: PasswordGeneratorProject,
      subheading: "React",
      title: "Password Generator",
      description: "Generate a password w/ stats on its security 🤓",
      link: "https://password-generator-jade-eight.vercel.app/",
    },
    {
      key: 1,
      image: GithubProjectImage,
      subheading: "React",
      title: "GitHub User Search",
      description: "Stats about specific github users",
      link: "https://github-user-search-alpha.vercel.app/",
    },
    {
      key: 2,
      image: AdviceGeneratorImage,
      subheading: "React",
      title: "Advice Generator",
      description:
        "Uses the Advice Slip API to generate random quotes of advice",
      link: "https://advice-generator-mu-six.vercel.app/",
    },
    {
      key: 3,
      image: RatingComponentProject,
      subheading: "React",
      title: "Rating Component",
      description: "Handling user interactions and updating the DOM.",
      link: "https://interactive-rating-component-weld.vercel.app/",
    },
    {
      key: 4,
      image: ELearningLandingProject,
      subheading: "React",
      title: "E-Learning Landing Page",
      description: "Responsive Page Project",
      link: "https://e-learning-landing-page.vercel.app/",
    },
  ];

  const footerProps: FooterProps = {
    cta: "Sound interesting?",
    buttonLink: "https://www.linkedin.com/in/raghavramanathan/",
    buttonText: "Let's chat!",
    options: navBarOptions,
  };

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Raghav Ramanathan</title>
        <link rel="canonical" href="https://raghav.wiki" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="bg-primarygreen">
        <NavBar options={navBarOptions}></NavBar>
        <Hero {...heroProps}></Hero>
      </div>
      <div id="about">
        <About {...aboutProps}></About>
      </div>
      <div id="projects">
        <Projects projects={projects}></Projects>
      </div>
      <div id="contact">
        <Footer {...footerProps}></Footer>
      </div>
    </main>
  );
};

export default IndexPage;
