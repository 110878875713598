import React from "react";
import { Button } from "../button";
import { NavBar, NavBarOption } from "../navbar";

export type FooterProps = {
  cta: string;
  buttonLink: string;
  buttonText: string;
  options: NavBarOption[];
};

export const Footer: React.FC<FooterProps> = ({
  cta,
  buttonLink,
  buttonText,
  options,
}) => {
  return (
    <div className="bg-primaryyellow">
      <div className="flex flex-col items-center justify-center py-20">
        <h2 className="font-display text-2xl md:text-3xl pb-10"> {cta} </h2>
        <Button text={buttonText} link={buttonLink}></Button>
      </div>
      <div className="hidden md:block">
        <div className="border-t-2 border-black mx-10 md:mx-20 xl:mx-32"></div>
        <NavBar options={options}></NavBar>
        <div className="border-t-2 border-black mx-10 md:mx-20 xl:mx-32 pb-20"></div>
      </div>
    </div>
  );
};
